import './chat.css'
import { MainContainer, Sidebar, Conversation, ConversationList, ChatContainer,
    MessageList, MessageInput, ConversationHeader, Avatar, Message } from '@chatscope/chat-ui-kit-react'
import  '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import { useState, useContext,useEffect } from 'react';
import { ZappaContext } from './context/zappa';
import axios from "axios";
import { Navigate, Route, Routes, useNavigate,useParams } from "react-router-dom";
import MenuSandwich from "./components/MenuSandwich";

export default function Chat() {
    const [disabled, setDisabled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [fluxoJson, setFluxoJson] = useState('');
    const { zappaMessageList, addMessage } = useContext(ZappaContext);
    const navigate = useNavigate();
    const { id } = useParams();
    
    const items: MenuSandwichItem[] = [
        {
          id: "1",
          label: "Iniciar novo Atendimento",
          onClick: () => {navigate("/");},
        },
        {
          id: "2",
          label: "Carregar Fluxo",
          onClick: () => {navigate("/fluxo");},
        },
        {
          id: "2",
          label: "Flow chart",
          onClick: () => {navigate("/flowchart");},
        }
      ];
      
      const handleCloseDrawer = () => {
        setIsOpen(false);
      };

    useEffect(() => {
        if(id?.length == 0)
        {
            alert('Telefone é obrigatório para iniciar um atendimento');
            navigate("/");
        }
    },[])

    const loadFluxo = async () =>{
        setDisabled(true);
        try{
            const response = await axios.post('https://x6kmqfzqpd.execute-api.us-east-1.amazonaws.com/staging/fluxo/upload',
             { nome:'teste name', fluxoJson}
            );
            alert(response.data)
            setDisabled(false);
        }
        catch(error: any){
            alert(error.response.data)
            setDisabled(false);
        }        
    }
    const sendMessage = async (value: string) => {
        addMessage({
            id:  Math.random(),
            message:value,
            sender: 'Léo - '+id,
            sentTime: '1 min atrás',
            direction: 'incoming',
            position: 'right',
            avatar: 'https://cdn.iconscout.com/icon/free/png-512/free-avatar-370-456322.png'
        })

        try{
            const response = await axios.post('https://x6kmqfzqpd.execute-api.us-east-1.amazonaws.com/staging/fluxo/mensagem',
             { telefone:id, mensagem: value}
            );
            response.data.map((mensagemResposta: string) => (
                addMessage({
                    id: Math.random(),
                    message: mensagemResposta,
                    sender: 'Zappa',
                    sentTime: '1 min atrás',
                    direction: 'outgoing',
                    position: 'left',
                    avatar: 'https://images.squarespace-cdn.com/content/v1/5df12180d612197abe6a30c4/bd265c8e-10c6-4337-bf3e-6c3b363e3ec2/Free+Zap+icon.png'
                })
            ))

            setDisabled(false);
            
        }
        catch(error: any){
            alert(error.response.data);
            setDisabled(false);
        }     

    };

    return(
        <div className="chatPage-div">
            <MenuSandwich isOpen={isOpen} onClose={handleCloseDrawer} items={items} />
            <MainContainer>
                {/* 
                <Sidebar position="left">
                <div className="container">
                    <div className="header">
                        <b>Json do seu fluxo </b>
                    </div>
                    <div className="content">
                        <textarea 
                            value={fluxoJson}
                            onChange={e => setFluxoJson(e.target.value)}
                        />
                    </div>
                    <div className="footer">
                        <button className='botao'
                            onClick={() => loadFluxo()} >
                                {disabled ?
                                (
                                    <p>Carregando...</p>
                                ):
                                (
                                    <p>Enviar</p>
                                )}
                        </button>
                    </div>
                    </div>
                    <ConversationList>
                        <Conversation 
                            name="Carregue aqui o seu Json"
                            lastSenderName="Esse arquivo deve conter seu fluxo"
                            active={true}>
                            <Avatar 
                                src="https://images.squarespace-cdn.com/content/v1/5df12180d612197abe6a30c4/bd265c8e-10c6-4337-bf3e-6c3b363e3ec2/Free+Zap+icon.png"
                            />
                        </Conversation>
                    </ConversationList> 
                </Sidebar>*/}
                <ChatContainer>
                    <ConversationHeader>
                            <Avatar 
                                src="https://images.squarespace-cdn.com/content/v1/5df12180d612197abe6a30c4/bd265c8e-10c6-4337-bf3e-6c3b363e3ec2/Free+Zap+icon.png"
                            />
                        <ConversationHeader.Content 
                            userName="Zappa - Sistema de automatização de atendimento "
                            info={`Atendendo o telefone ${id}`}>
                        </ConversationHeader.Content>
                    </ConversationHeader>
                    <MessageList>
                    {zappaMessageList != null && zappaMessageList.zappaMessages.map((zappa, key) => (
                        <Message
                            key={zappa.id}
                            model={{
                                message:zappa.message,
                                sender:zappa.sender,
                                sentTime:zappa.sentTime,
                                direction:zappa.direction,
                                position:zappa.position,
                            }}
                        >
                            <Avatar 
                                src={zappa.avatar}
                            />
                            </Message>   
                        ))}
                    </MessageList>
                    <MessageInput 
                        placeholder="Digite aqui sua mensagem"
                        onSend={(_, textContent) => sendMessage(textContent)}
                        disabled={disabled} 
                        />
                </ChatContainer>
            </MainContainer>
        </div>
    )
}