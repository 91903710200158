import './fluxo.css'
import { useState , useCallback} from 'react';
import axios from "axios";
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState, useEdgesState, applyEdgeChanges, applyNodeChanges
  } from 'reactflow';
import 'reactflow/dist/style.css';

export default function Fluxo() {

  const [fluxoJson, setFluxoJson] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showFlowChart, setShowFlowChart] = useState(false);

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const onNodesChange = useCallback(
    (changes: any) => setNodes((nds: any) => applyNodeChanges(changes, nds)),
    [],
  );
  const onEdgesChange = useCallback(
    (changes: any) => setEdges((eds: any) => applyEdgeChanges(changes, eds)),
    [],
  );


  /*
  const initialNodes = [
    { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
    { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
  ];
  const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];
*/  


  const loadFluxo = async () =>{
    setDisabled(true);
    try{
        const response = await axios.post('https://x6kmqfzqpd.execute-api.us-east-1.amazonaws.com/staging/fluxo/upload', 
         { nome:'teste name', fluxoJson}
        );
        setNodes(response.data.nodes);
        setEdges(response.data.edges);
        setDisabled(false);
        setShowFlowChart(true);
    }
    catch(error: any){
        alert(error.response.data)
        setDisabled(false);
    }        
}

  return (
    <div className="container">
        {!showFlowChart ? 
        <div >
            <p>Faça o upload do seu fluxo em json</p>
            <textarea 
                className="textarea"
                value={fluxoJson}
                onChange={e => setFluxoJson(e.target.value)}
            />
                <button 
                    onClick={() => loadFluxo()} >
                        {disabled ?
                        (
                            <>Carregando...</>
                        ):
                        (
                            <>Enviar</>
                        )}
                </button>
        </div>
        :
        <div style={{ width: '100vw', height: '100vh', background: 'white' }}>
            <ReactFlow nodes={nodes} edges={edges} onNodesChange={onNodesChange}  onEdgesChange={onEdgesChange}>
                <Controls />
                <MiniMap />
                <Background gap={12} size={1} />
            </ReactFlow>
        </div>
        }
    </div>
);
                      }