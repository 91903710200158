import React, { createContext, useState, useCallback, ReactNode } from 'react';

interface ZappaContextDataProps {
	children: ReactNode;
  }

interface ZappaContextData {
	zappaMessageList: ZappaMessageList;
	addMessage: (new_item: ZappaMessage) => void;
	clearMessages: () => void
	
}

export const ZappaContext = createContext<ZappaContextData>({} as ZappaContextData);

export const ZappaContextProvider = ({ children } : ZappaContextDataProps) => {
	const [zappaMessages, setZappaMessages] = useState<ZappaMessage[]>([]);


	const addMessage = useCallback((new_item: ZappaMessage) => {
		setZappaMessages((prevMessages) => [...prevMessages, new_item]);
	}, []);

	const clearMessages = useCallback(() => {
		setZappaMessages([]);
	}, []);


	return (
		<ZappaContext.Provider
			value={{
				zappaMessageList: {
					zappaMessages
				},
				addMessage,
				clearMessages
			}}
		>
			{children}
		</ZappaContext.Provider>
	);
}
