import React, { useState } from "react";
import { Drawer, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import { Menu } from "@material-ui/icons";

import './MenuSandwich.css'

interface MenuSandwichProps {
  isOpen: boolean;
  onClose: () => void;
  items: MenuSandwichItem[];
}


const MenuSandwich: React.FC<MenuSandwichProps> = ({ isOpen, onClose, items }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(isOpen);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="divMenu">
      <IconButton onClick={handleOpenDrawer}>
        <Menu />
      </IconButton>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer} 
      classes={{
        paper: "menucontainer",
      }}>
        <List>
          {items.map((item) => (
            <ListItem key={item.id} onClick={item.onClick}>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default MenuSandwich;