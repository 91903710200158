import './atendimento.css'
import {  useNavigate } from "react-router-dom";
import { useState } from 'react';
import { IMaskInput } from "react-imask";
import { ZappaContext } from './context/zappa';
import {  useContext} from 'react';


export default function Atendimento() {
    const navigate = useNavigate();
    const [inputText, setInputText] = useState("");
    //const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const {  clearMessages } = useContext(ZappaContext);
 
    const handleIniciarChat = () => {
        var telefone = inputText.replace('(','').replace(')','').replace('-','');
        clearMessages();
        navigate(`/chat/${telefone}`);
    };

    const handleOnChange = (value: any) => {
        setInputText(value);
      };

    return (
            <div className="container">
                <h1>Zappa</h1>
                <p>Automatização de atendimento</p>
                    <IMaskInput
                        onAccept={handleOnChange}
                        className="input"
                        mask="(00)00000-0000"
                        placeholder="Digite o telefone de atendimento..."
                    />
                <button onClick={handleIniciarChat}> Começar</button>
            </div>
    );

}