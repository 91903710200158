import './fluxo.css'
import { useState } from 'react';
import axios from "axios";
import ReactFlow, {
    MiniMap,
    Controls,
    Background
  } from 'reactflow';
import 'reactflow/dist/style.css';

export default function Flowchart() {

  const [fluxoJson, setFluxoJson] = useState('');
  const [disabled, setDisabled] = useState(false);

  const initialNodes = [
    { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
    { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
  ];
  const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

  const loadFluxo = async () =>{
    setDisabled(true);
    try{
        const response = await axios.get('https://x6kmqfzqpd.execute-api.us-east-1.amazonaws.com/staging/flowchart');
        alert(response.data)
        setDisabled(false);
    }
    catch(error: any){
        alert(error.response.data)
        setDisabled(false);
    }        
}

  return (
    <div className="container">
        <p>Flowchart</p>
        <button 
                  onClick={() => loadFluxo()} >
                      {disabled ?
                      (
                          <>Carregando...</>
                      ):
                      (
                          <>Enviar</>
                      )}
              </button>
        <div style={{ width: '100vw', height: '100vh', background: 'white' }}>
            <ReactFlow nodes={initialNodes} edges={initialEdges} >
                <Controls />
                <MiniMap />
                <Background gap={12} size={1} />
            </ReactFlow>
        </div>
    </div>
);
                      }