import React from 'react';
import logo from './logo.svg';
import './App.css';
import Chat from './chat';
import { ZappaContextProvider } from './context/zappa'
import { Routes, Route  } from 'react-router-dom';
import Atendimento from './atendimento';
import Fluxo from './fluxo';
import Flowchart from './flowchart';

function App() {
  return (

      <div className="App">
        <ZappaContextProvider>
        <Routes>
            <Route path="/chat/:id?" element={<Chat />} />
            <Route path="/" element={<Atendimento />} />
            <Route path="/fluxo" element={<Fluxo />} />
            <Route path="/flowchart" element={<Flowchart />} />
         </Routes>
        </ZappaContextProvider>
      </div>
  );
}

export default App;
